const curriculum = {
	workExperience: [
		{
			name: "Gyldendal",
			role: "Creative director",
			period: "2019 -",
			roleChanges: [
				{
					role: "Creative director of Education",
					start: "2020",
					end: "2022",
				},
			],
		},
		{
			name: "HAIEK",
			role: "Founder, designer and developer",
			period: "2011 -",
		},
		{
			name: "Bleed Design Studio",
			role: "Senior designer and developer",
			period: "2011 - 2019",
			roleChanges: [
				{ role: "Designer and developer", start: "2013", end: "2015" },
				{ role: "Junior designer", start: "2011", end: "2013" },
			],
		},
		{
			role: "Freelance designer",
			period: "2009 - 11",
		},
		{
			name: "Schjærven Interactive",
			role: "Designer",
			period: "2008 - 09",
		},
	],
	clients: [
		{
			name: "Bionic",
		},
		{
			name: "Blank Architects",
		},
		{
			name: "Bleed Design",
		},
		{
			name: "Grafill",
		},
		{
			name: "Gyldendal",
		},
		{
			name: "Innovation Norway",
		},
		{
			name: "KIDS Records",
		},
		{
			name: "Laola1",
		},
		{
			name: "Mikme",
		},
		{
			name: "Newslab",
		},
		{
			name: "Nico Widerberg",
		},
		{
			name: "Norwegian",
		},
		{
			name: "Norwegian Directorate of Health, the",
		},
		{
			name: "NDC Conferences",
		},
		{
			name: "NPRO",
		},
		{
			name: "NRK",
		},
		{
			name: "Oslo, Municipality of",
		},
		{
			name: "Pir II",
		},
		{
			name: "Questback",
		},
		{
			name: "Rallye Japan",
		},
		{
			name: "Romlab",
		},
		{
			name: "Rødt",
		},
		{
			name: "Steen & Strøm",
		},
		{
			name: "Telenor",
		},
		{
			name: "Tom Wood",
		},
		{
			name: "Universal Music",
		},
		{
			name: "Visit Norway",
		},
		{
			name: "Zynk",
		},
	],
	talks: [
		{
			title: "Digital Transformation in Education",
			type: "Talk",
			year: 2022,
			description: "Posten, Oslo",
		},
		{
			title: "Bleed vs Snøhetta",
			type: "Talk",
			year: 2018,
			description: "Grafill Haugalandet, Haugesund",
		},
		{
			title: "Design + Code",
			type: "Talk",
			year: 2014,
			description: "Designinstituttet, Oslo",
		},
		{
			title: "Design + Code",
			type: "Talk",
			year: 2014,
			description: "Grafill Haugalandet, Haugesund",
		},
	],
	exposure: [
		{
			title: "Pseudo",
			type: "Book feature",
			year: 2018,
			description: "Pseudonym Publishing",
		},
		{
			title: "Truly Nordic",
			type: "Book feature",
			year: 2018,
			description: "Victionary",
		},
		{
			title: "Brand Addiction",
			type: "Book feature",
			year: 2017,
			description: "Flamant",
		},
		{
			title: "Bleed combines nature and generative art",
			type: "Magazine feature",
			year: 2017,
			description: "It's Nice That",
			url: "https://www.itsnicethat.com/articles/bleed-blank-architects-graphic-design-201017",
		},
		{
			title: "Where Does a Body End",
			type: "Exhibition",
			year: 2016,
			description: "Vienna Design Week",
		},
		{
			title: "Los Logos 6",
			type: "Book feature",
			year: 2014,
			description: "Gestalten",
		},
		{
			title: "Los Logos Compass",
			type: "Book feature",
			year: 2010,
			description: "Gestalten",
		},
	],
	awards: [
		{
			type: "Gold",
			contest: "Visuelt",
			year: 2018,
			project: "Bleed Web",
		},
		{
			type: "Gold",
			contest: "Visuelt",
			year: 2018,
			project: "Pseudonym App",
		},
		{
			type: "Nomination",
			contest: "IxDA Award",
			year: 2018,
			project: "Pseudonym App",
		},
		{
			type: "Gold",
			contest: "European Design Awards",
			year: 2017,
			project: "Newslab",
		},
		{
			type: "Diploma",
			contest: "Visuelt",
			year: 2017,
			project: "Blank Architects identity",
		},
		{
			type: "Diploma",
			contest: "Visuelt",
			year: 2017,
			project: "Blank Architects web",
		},
		{
			type: "Finalist",
			contest: "European Design Awards",
			year: 2017,
			project: "Blank Architects web",
		},
		{
			type: "Silver",
			contest: "European Design Awards",
			year: 2016,
			project: "Northern Lights app",
		},
		{
			type: "Diploma",
			contest: "Visuelt",
			year: 2016,
			project: "Norwegian Taxi",
		},
		{
			type: "Diploma",
			contest: "Visuelt",
			year: 2016,
			project: "Northern Lights app",
		},
		{
			type: "Diploma",
			contest: "Visuelt",
			year: 2015,
			project: "NRK Annual Report",
		},
		{
			type: "Winner",
			contest: "Digital Communication Awards",
			year: 2014,
			project: "Slutta app",
		},
		{
			type: "Diploma",
			contest: "Visuelt",
			year: 2014,
			project: "NRK Annual Report",
		},
		{
			type: "Gold",
			contest: "European Design Awards",
			year: 2013,
			project: "Bleed.com",
		},
		{
			type: "Diploma",
			contest: "Visuelt",
			year: 2013,
			project: "Bleed.com",
		},
	],
	skills: [
		{
			name: "Craft",
		},
		{
			name: "CSS, SCSS, LESS",
		},
		{
			name: "Express",
		},
		{
			name: "Figma",
		},
		{
			name: "Framer",
		},
		{
			name: "Firebase",
		},
		{
			name: "Git",
		},
		{
			name: "Javascript",
		},
		{
			name: "MySQL",
		},
		{
			name: "Node",
		},
		{
			name: "PHP",
		},
		{
			name: "React",
		},
		{
			name: "Sanity",
		},
		{
			name: "Sketch",
		},
		{
			name: "Swift",
		},
		{
			name: "Typescript",
		},
		{
			name: "Vue",
		},
	],
};

export default curriculum;
