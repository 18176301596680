import curriculum from "./data/curriculum";
import { groupArrayBy } from "./utils/formatting";
import "./App.css";

const App = () => {
	const groupAwardsByYear = groupArrayBy(curriculum.awards, "year");

	const awardsInArray = () => {
		const arr = [];
		const keys = Object.keys(groupAwardsByYear);
		keys.sort().reverse();
		for (var i = 0; i < keys.length; ++i) {
			arr.push(groupAwardsByYear[keys[i]]);
		}
		return arr;
	};

	return (
		<div className="App">
			<div className="App-info-column">
				<header className="App-header">
					<h1>Kristoffer Lundberg</h1>
					<div className="App-header-info">
						Creative Director, Gyldendal
						<br />
						Oslo, Norway
					</div>
				</header>

				<figure className="App-info-section">
					<figcaption className="App-info-section-label">
						Work exp.
					</figcaption>
					<ul>
						{curriculum.workExperience.map((work, workIndex) => (
							<li key={workIndex}>
								{work.role} {work.name ? `at ${work.name}` : ""} (
								{work.period})
								{work.roleChanges && (
									<ul>
										{work.roleChanges.map(
											(roleChange, roleChangeIndex) => (
												<li
													key={workIndex * roleChangeIndex}
													className="App-rolechange-container"
												>
													* {roleChange.role} from{" "}
													{roleChange.start} to {roleChange.end}
												</li>
											)
										)}
									</ul>
								)}
							</li>
						))}
					</ul>
				</figure>

				<figure className="App-info-section">
					<figcaption className="App-info-section-label">
						Awards
					</figcaption>
					<div>
						{awardsInArray().map((awardGrouping, groupIndex) => (
							<div key={groupIndex}>
								<div
									className={`App-info-textlabel ${
										groupIndex === 0 ? " first" : ""
									}`}
								>
									({awardGrouping[0][0].year})
								</div>
								<ul>
									{awardGrouping.map((award, awardIndex) => (
										<li key={awardIndex}>
											{award[0].type} at {award[0].contest} /{" "}
											{award[0].project}
										</li>
									))}
								</ul>
							</div>
						))}
					</div>
				</figure>

				<figure className="App-info-section">
					<figcaption className="App-info-section-label">
						Exposure
					</figcaption>
					<ul>
						{curriculum.exposure.map((item, itemIndex) => (
							<li key={itemIndex}>
								{item.title} ({item.type}, {item.description},{" "}
								{item.year})
							</li>
						))}
					</ul>
				</figure>

				<figure className="App-info-section">
					<figcaption className="App-info-section-label">Talks</figcaption>
					<ul>
						{curriculum.talks.map((talk, talkIndex) => (
							<li key={talkIndex}>
								{talk.title} at {talk.description} ({talk.year})
							</li>
						))}
					</ul>
				</figure>
			</div>
		</div>
	);
};

export default App;
